import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Referentiel } from 'src/app/data/declaration/models/referentiel.model';
import { SaisieConditionnement } from 'src/app/data/declaration/models/saisie-conditionnement.model';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { OperateurSitesService } from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';

@Component({
  selector: 'app-vue-conditionnement',
  templateUrl: './vue-conditionnement.component.html',
  styleUrls: ['./vue-conditionnement.component.scss']
})
export class VueConditionnementComponent implements OnInit {

@Input() idMouvement :number;
@Input() public idOperateur: number;

conditionnement : SaisieConditionnement;
public referentiel: Referentiel;

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly operateurSitesService: OperateurSitesService,
    private readonly referentielService: ReferentielService,
    private readonly translate: TranslateService,
    private readonly mouvementsProduitsService: MouvementsProduitsService,
    private readonly modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  /** Charge la liste des mouvements non lus et le référentiel */
  private loadData() {
    forkJoin(
      this.mouvementsProduitsService.getConditionnement(this.idOperateur,  this.idMouvement ),
      this.referentielService.getReferentiel()
    ).pipe().subscribe(
      ([conditionnement, referentiel]) => {
        this.conditionnement = conditionnement;
        this.referentiel = referentiel;
      });
  }

  private getLibelleContenant(idType: number){

    return this.referentiel.refContenantList.find(c => c.idContenant === idType).libelleContenant;

  }

   /** Ferme la fenête */
   public onClose(): void {
    this.modal.dismiss();
  }

}
