<div>
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="changement">{{changement.typeChangement}}</h4>
    <div class="pointer">
      <fa-icon icon="times" (click)="onClose()"></fa-icon>
    </div> 
  </div>

  <div class="modal-body">
    
      <div class="row" *ngIf="changement">
        <div class="col">
          
          <div  class="py-2" *ngIf="changement.produitOrigine">
              <div class="libelle" translate>page.declarations.synthese.modal.changementDenomination.origine</div>
            <div class="valeur">
              {{changement.produitOrigine.libelle}}
            </div>          
          </div>

          <div class="py-2" *ngIf="changement.produitDestination">
            <div class="libelle" translate>page.declarations.synthese.modal.changementDenomination.devient</div>
            <div class="valeur">
              {{changement.produitDestination.libelle}}
            </div>    
          </div>
          
          <div class="py-2" *ngIf="changement.cepages">
            <div class="libelle" translate>page.declarations.synthese.modal.changementDenomination.cepages</div>
            <div class="valeur">
              {{getCepages(changement.cepages)}}
            </div>    
          </div>

          <div  class="py-2" >
            <div class="libelle" translate>page.declarations.synthese.modal.changementDenomination.volume</div>
            <div class="valeur">
              {{changement.quantite}}
            </div> 
          </div>

          <div  class="py-2" >
            <div class="libelle" translate>page.declarations.synthese.modal.changementDenomination.logement</div>
            <div class="valeur">
              {{changement.logement}}
            </div> 
          </div>
        </div>

        <div class="col">
          <div  class="py-2" >
            <div class="libelle" translate>page.declarations.synthese.modal.changementDenomination.typeDeLot</div>
            <div class="valeur">{{getConditionnement(changement.typeConditionnement)}}</div>
          </div>

          <div  class="py-2" >
            <div class="libelle" translate>page.declarations.synthese.modal.changementDenomination.numeroLot</div>
            <div class="valeur">
              {{changement.numeroLot}}
            </div> 
          </div>

          <div  class="py-2" >
            <div class="libelle" translate>page.declarations.synthese.modal.changementDenomination.entreposage</div>
            <div class="valeur" *ngIf="changement.site">
               {{changement.site.adresse}} 
            </div> 
          </div>

          <div  class="py-2" >
            <div class="libelle" translate>page.declarations.synthese.modal.changementDenomination.observation</div>
            <div class="valeur">
              {{changement.observation}}
            </div> 
          </div>
        </div>
      </div>

      
  </div>
</div>
