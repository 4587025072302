import { JsonProperty, JsonObject, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';

import { Cepage } from './cepage.model';
import { MouvementDestination } from './mouvement-destination.model';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { Site } from './site.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Produit } from '../../habilitation/models/produit.model';

@JsonObject
export class SaisieRevendication {

  @JsonProperty('codeProduit', String)
  codeProduit: string;

  @JsonProperty('libelleProduit', String)
  libelleProduit: string;

  @JsonProperty('idsCepages', [Number])
  @CustomConverter(DataIdConverterFactory.build(Cepage, 'cepages'))
  @Optional
  cepages: Cepage[];

  @JsonProperty('idsDestinations', [Number])
  @CustomConverter(DataIdConverterFactory.build(MouvementDestination, 'mouvementsDestinations'))
  destinations: MouvementDestination[];

  @JsonProperty('date', String)
  @CustomConverter(DateConverter)
  date: moment.Moment;

  @JsonProperty('idSite', Number)
  @CustomConverter(DataIdConverterFactory.build(Site, 'site'))
  entreposage: Site;

  @JsonProperty('logement', String)
  logement: string;

  @JsonProperty('numeroLot', String)
  numeroLot: string;

  @JsonProperty('degre', Number)
  @Optional
  degre: number;

  @JsonProperty('volume', Number)
  volume: number;

  @JsonProperty('observation', String)
  @Optional
  observation: string;

}
