<div class="content">
  <div class="content-panel">
    <!-- Formulaire de recherche sur l'année -->
    <form [formGroup]="formAnnee" #form="ngForm">
      <div class="form-group">
        <!-- Année -->
        <label translate>page.declarations.historique-mouvements.table.header.annee</label> :
        <input id="annee" formControlName="annee" maxlength="4"
          [ngClass]="{ 'is-invalid': annee.dirty && annee.invalid }" class="form-control annee-input" />
      </div>
    </form>
    <!-- Fin formulaire de recherche sur l'année -->

    <!-- Tableau historique déclarations opérateur -->
    <div *ngIf="historiques">
      <h2 translate>page.declarations.historique-mouvements.title</h2>

      <!-- Formulaire de recherche avancée -->
      <form [formGroup]="formRecherche" #form="ngForm" class="form-inline p-2"
        style="flex-direction: column; align-items: flex-start;">
        <div class="row">
          <div class="form-group">
            <!-- Type déclaration -->
            <label for="typeDeclatation" translate>page.declarations.historique-mouvements.table.header.typeDeclaration</label> :
            <div *ngIf="referentiel.refMouvements">
              <app-multiselect id="typeDeclatation" controlName="typeDeclatation" [items]="referentiel.refMouvements"
                bindLabel="libelle" maxWidth="initial" class="form-control-multiselect type-input">
              </app-multiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <!-- Numéro de lot -->
            <label translate>page.declarations.historique-mouvements.table.header.numeroLot</label> :
            <input id="numeroLot" formControlName="numeroLot" class="form-control other-input" />
          </div>
          <div class="form-group">
            <!-- Cahier des charges -->
            <label translate>page.declarations.historique-mouvements.table.header.cdc</label> :
            <input id="cdc" formControlName="cdc" class="form-control other-input" />
          </div>
          <div class="form-group">
            <!-- Produit -->
            <label translate>page.declarations.historique-mouvements.table.header.produit</label> :
            <input id="produit" formControlName="produit" class="form-control other-input" />
          </div>
          <div class="form-group">
            <!-- N° de dossier -->
            <label translate>page.declarations.historique-mouvements.table.header.numeroDossier</label> :
            <input id="numeroDossier" formControlName="numeroDossier" class="form-control other-input" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <!-- Date début déclaration -->
            <label translate>page.declarations.historique-mouvements.form.dateDeclarationEntre</label> :
            <input id="debut" formControlName="debut" ngbDatepicker #dateDebutPicker="ngbDatepicker"
              class="form-control other-input" />
            <button type="button" (click)="dateDebutPicker.toggle()" class="btn btn-secondary">
              <fa-icon icon="calendar-alt"></fa-icon>
            </button>
          </div>
          <div class="form-group">
            <!-- Date fin déclaration -->
            <label translate>page.declarations.historique-mouvements.form.et </label> :
            <input id="fin" formControlName="fin" ngbDatepicker #dateFinPicker="ngbDatepicker"
              class="form-control other-input" />
            <button type="button" (click)="dateFinPicker.toggle()" class="btn btn-secondary">
              <fa-icon icon="calendar-alt"></fa-icon>
            </button>
          </div>
        </div>

      </form>

      <!-- Fin formulaire de recherche avancée -->
      <table table class="table table-striped" *ngIf="displayedHistoriques?.length > 0; else aucunHistorique">
        <thead>
          <tr>
            <th class="liste-col-header" translate>page.declarations.historique-mouvements.table.header.typeDeclaration
            </th>
            <th class="liste-col-header" translate>page.declarations.historique-mouvements.table.header.date</th>
            <th class="liste-col-header" translate>page.declarations.historique-mouvements.table.header.volumeTotal</th>
            <th class="liste-col-header" translate>page.declarations.historique-mouvements.table.header.cdc</th>
            <th class="liste-col-header" translate>page.declarations.historique-mouvements.table.header.produit</th>
            <th class="liste-col-header" translate>page.declarations.historique-mouvements.table.header.annee</th>
            <th class="liste-col-header" translate>page.declarations.historique-mouvements.table.header.numeroLot</th>
            <th class="liste-col-header" translate>page.declarations.historique-mouvements.table.header.numeroDossier</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let historique of displayedHistoriques">
            <td>{{ getTypeMouvement(historique.code) }}</td>
            <td>{{ historique.date | date:'dd/MM/yyyy' }}</td>
            <td>{{ historique.volume | number:'1.2-2' }}</td>
            <td>{{ historique.libelleCahier }}</td>
            <td>{{ historique.produit?.libelle }}</td>
            <td>{{ historique.annee }}</td>
            <td>{{ historique.numeroLot }}</td>
            <td>{{ historique.numeroDossier }}</td>
            <td><h4  (click)="onEdit(historique)">
              <fa-icon icon="eye"></fa-icon>                 
            </h4></td>
          </tr>
        </tbody>
      </table>
      <!-- Fin tableau historique déclarations opérateur -->
    </div>
  </div>

  <!-- Template aucun historique -->
  <ng-template #aucunHistorique>
    <div class="alert alert-warning alert-block" translate>
      <fa-icon icon="exclamation"></fa-icon> page.declarations.historique-mouvements.aucunHistorique
    </div>
  </ng-template>
  <!-- Fin template aucun historique -->
