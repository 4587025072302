import { JsonObject, JsonProperty, Optional, CustomConverter } from '@thorolf/json-ts-mapper';
import { Cepage } from './cepage.model';
import { StockCode } from './enums/stock.enum';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Produit } from '../../habilitation/models/produit.model';
import { Site } from './site.model';

@JsonObject
export class ChangementDenomination {
  @JsonProperty('codeProduitOrigine', String)
  codeProduitOrigine: string;

  @JsonProperty('codeProduitDestination', String)
  codeProduitDestination: string;

  @JsonProperty('quantite', Number)
  quantite: number;

  @JsonProperty('logement', String)
  logement: string;

  @JsonProperty('typeConditionnement', String)
  @Optional
  typeConditionnement: string;

  @JsonProperty('numeroLot', String)
  @Optional
  numeroLot: string;

  @JsonProperty('idSite', Number)
  idSite: number;

  @JsonProperty('codeStock', String)
  codeStock: StockCode;

  @JsonProperty('idsCepages', [Number])
  @CustomConverter(DataIdConverterFactory.build(Cepage, 'cepages'))
  @Optional
  cepages: Cepage[];

  @JsonProperty('observation', String)
  @Optional
  observation: string;

  @JsonProperty('typeChangement', String)
  @Optional
  typeChangement: string;

  produitOrigine: Produit;
  produitDestination: Produit;
  site: Site;
}
