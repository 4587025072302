import { JsonObject, JsonProperty, Any, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { Assemblage } from './assemblage.model';
import { Contenant } from './contenant.model';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import * as moment from 'moment';
import { Cepage } from './cepage.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { Produit } from '../../habilitation/models/produit.model';
import { Site } from './site.model';

@JsonObject
export class SaisieConditionnement {
  @JsonProperty('codeProduit', String)  
  codeProduit: string;

  @JsonProperty('date', String)
  @CustomConverter(DateConverter)
  date: moment.Moment;

  @JsonProperty('logement', String)
  logement: string;

  @JsonProperty('numeroLot', String)
  numeroLot: string;

  @JsonProperty('volume', Number)
  volume: number;

  @JsonProperty('idSite', Number)
  idSite: number;

  @JsonProperty('assemblages', [Assemblage])
  @Optional
  assemblages: Assemblage[];

  @JsonProperty('contenants', [Contenant])
  @Optional
  contenants: Contenant[];

  @JsonProperty('idsCepages', [Number])
  @CustomConverter(DataIdConverterFactory.build(Cepage, 'cepages'))
  @Optional
  cepages: Cepage[];

  @JsonProperty('observation', String)
  @Optional
  observations: string;

  produit: Produit;
  site: Site;
}
