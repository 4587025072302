<div>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'page.declarations.synthese.modal.saisirTirage.tirage' | translate }}</h4>
    <div class="pointer">
      <fa-icon icon="times" (click)="onClose()"></fa-icon>
    </div>  
  </div>

  <div class="modal-body">
      <div class="row" *ngIf="tirage">
        <div class="col" >
           <!-- produit -->
           <div>
            <div class="libelle">{{ 'page.declarations.synthese.modal.saisirTirage.produitBase' | translate }}</div>
            <div class="valeur" >{{tirage.produitBase.libelle}}</div> 
          </div>
          <!-- produit -->
          <div  class="py-2">
            <div class="libelle">{{ 'page.declarations.synthese.modal.saisirTirage.produit' | translate }}</div>
            <div class="valeur" >{{tirage.produit.libelle}}</div> 
          </div>
          <!-- date de tirage -->
          <div  class="py-2">
            <div class="libelle">{{ 'page.declarations.synthese.modal.saisirTirage.dateTirage' | translate }}</div>
            <div class="valeur" >{{tirage.dateTirage | date:'dd/MM/yyyy'}}</div>             
          </div>
          <!-- numéro tirage -->
          <div class="py-2">
            <div class="libelle">{{ 'page.declarations.synthese.modal.saisirTirage.numeroTirage' | translate }}</div>
            <div class="valeur" >{{tirage.numeroTirage}}</div> 
          </div>
          <!-- numéro de lot -->
          <div  class="py-2">
            <div class="libelle">{{ 'page.declarations.synthese.modal.saisirTirage.numeroLot' | translate }}</div> 
              <div class="valeur">{{tirage.numeroLot}}</div>
          </div>
          <!-- type de vin -->
          <div class="py-2" >
            <div class="libelle">{{ 'page.declarations.synthese.modal.saisirTirage.typeVin' | translate }}</div>
            <div class="valeur" >{{tirage.typeVin?.libelle}}</div>           
          </div>                  
          <!-- prestataire de service -->
          <div  class="py-2">
            <div class="libelle" translate>page.declarations.synthese.modal.saisirTirage.prestataireService</div>
            <div classe="valeur">{{prestataire}}</div>     
          </div>
          <!-- Entreposage -->
          <div  class="py-2">
            <div class="libelle">{{ 'page.declarations.synthese.modal.saisirTirage.entreposage' | translate }}</div>
            <div class="valeur" >{{tirage.site!.adresse}}</div>           
          </div>
          <!-- -->
        </div>
        <div class="col">
          <div>
            <label class="libelle">{{'page.declarations.synthese.modal.saisirTirage.volumeVinBase' | translate }} ({{'page.declarations.synthese.modal.saisirTirage.hL' | translate }})</label>
           
          <!-- Formulaire assemblages -->
          <div  class="py-2" *ngIf="tirage.assemblages">
            <div class="row" >
              <div class="col-md-2" translate>page.declarations.synthese.modal.saisirTirage.annee</div>
              <div class="col-md-4" translate>page.declarations.synthese.modal.saisirTirage.volume</div>
            </div>
            <div class="row" *ngFor="let assemblage of tirage.assemblages">
              <div class="col-md-2">{{assemblage.annee}}</div>
              <div class="col-md-4">{{assemblage.volume}}</div>
            </div>            
          </div>
          
          <div class="py-2">
          <div class="libelle">{{'page.declarations.synthese.modal.saisirTirage.volumeLiqueur' | translate }} ({{'page.declarations.synthese.modal.saisirTirage.hL' | translate }})</div>
          <div class="valeur" >{{tirage.volumeLiqueur}}</div>
          </div>          

          <div class="py-2">
            <div class="libelle">{{ 'page.declarations.synthese.modal.saisirTirage.volumeTotal' | translate }} ({{ 'page.declarations.synthese.modal.saisirTirage.hL' | translate }})</div>
            <div class="valeur" >{{tirage.volumeVinBase + tirage.volumeLiqueur}}</div>
          </div>

          <hr/>


          <!-- Formulaire Contenants -->
          <div  class="py-2" *ngFor="let contenant of tirage!.contenants">
            <div class="libelle">{{ 'page.declarations.synthese.modal.saisirTirage.contenant' | translate }} </div>
            <div class="row" >
                <div class="col-md-4"><label>{{ 'page.declarations.synthese.modal.saisirTirage.type' | translate }}</label></div>
                <div class="col-md-4"><label>{{ 'page.declarations.synthese.modal.saisirTirage.nombre' | translate }}</label></div>
                <div class="col-md-3"><label>{{ 'page.declarations.synthese.modal.saisirTirage.volume' | translate }} ({{ 'page.declarations.synthese.modal.saisirTirage.hL' | translate }})</label></div>
              </div>
              <div class="row">
                  <div class="col-md-4" >{{getLibelleContenant(contenant.idType)}}</div>
                  <div class="col-md-4" >{{contenant.nombre}}</div>
                  <div class="col-md-3" >{{contenant.volume}}</div>                
              </div>
          </div>

          
        </div>
      </div>
  </div>
</div>
