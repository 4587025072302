import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { ProduitDestination } from '../../models/produit-destination.model';
import { Cache, ClearCache, CacheKey } from 'src/app/core/services/cache/cache.service';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { forkJoin, Observable, of } from 'rxjs';
import { ChangementDenomination } from '../../models/changement-denomination.model';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SaisieConditionnement } from '../../models/saisie-conditionnement.model';
import { SaisieRevendication } from '../../models/saisie-revendication.model';
import { TypeCahierCode } from 'src/app/data/habilitation/models/enums/type-cahier-code.enum';
import { SaisieTransaction } from '../../models/saisie-transaction.model';
import { HistoriqueMouvement } from '../../models/historique-mouvement.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { LotSaisiesRevendications } from '../../models/lot-saisies-revendications.model';
import { ObjetFacture } from 'src/app/data/facturation/models/objet-facture.model';
import { ObjetFactureDTO } from 'src/app/data/facturation/models/objet-facture-dto.model';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { SaisieTirage } from '../../models/saisie-tirage.model';
import { OperateurSitesService } from '../operateur-sites/operateur-sites.service';
import { ReferentielService } from '../referentiel/referentiel.service';

@Injectable({
  providedIn: 'root'
})
export class MouvementsProduitsService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly produitsService: ProduitsService,
    private readonly operateurService: OperateursService,
    private readonly operateurSitesService: OperateurSitesService,
    private readonly referentielService: ReferentielService
  ) { }

  @ClearCache
  clearCache() { }

   getProduitsDestinationDenomination(idOperateur: number, codeProduit: string, typeCahier: TypeCahierCode): Observable<ProduitDestination[]> {
    return this.http.get<object[]>(`/api/declaration/private/operateurs/${idOperateur}/produits/${btoa(codeProduit)}/destinations-denomination?typeCahier=${typeCahier}`).pipe(
      map(produits => this.mapper.deserializeArray(produits, ProduitDestination)),
      this.produitsService.getProduitPipe()
    );
  }

  setChangementDenomination(idOperateur: number, changementDenomination: ChangementDenomination, campagne: string): Observable<void> {
    return this.http.post(
      `/api/declaration/private/operateurs/${idOperateur}/mouvement-changement-denomination/${campagne}`,
      this.mapper.serialize(changementDenomination))
      .pipe(map(() => { }));
  }

  setConditionnement(idOperateur: number, annee: string, conditionnement: SaisieConditionnement): Observable<void> {
    return this.http.post(
      `/api/declaration/private/operateurs/${idOperateur}/mouvement-conditionnement/${annee}`,
      this.mapper.serialize(conditionnement))
      .pipe(map(() => { }));
  }

  creerRevendication(idOperateur: number, annee: string, revendication: SaisieRevendication): any {
    return this.http.post(
      `/api/declaration/private/operateurs/${idOperateur}/mouvement-revendications/${annee}`,
      this.mapper.serialize(revendication))
      .pipe(map(() => { }));
  }

  /**
   * Enregistre un lot de revendications et retourne un numéro de dossier associé
   * @param lotRevendications Le lot de revendications à enregistrer
   * @see LotSaisiesRevendications
   */
  creerLotRevendications(lotRevendications: LotSaisiesRevendications): Observable<string> {
    return this.http.post<string>(
      `/api/declaration/private/operateurs/${lotRevendications.idOperateur}/mouvement-revendications-groupe/${lotRevendications.campagne}`,
      this.mapper.serialize(lotRevendications.revendications)
    );
  }

    /**
   * Enregistre un lot de revendications et retourne un numéro de dossier associé
   * @param lotRevendications Le lot de revendications à enregistrer
   * @see LotSaisiesRevendications
   */
    creerLotRevendicationsAvantFacture(lotRevendications: LotSaisiesRevendications): Observable<ObjetFactureDTO[]> {
      return this.http.post<ObjetFactureDTO[]>(
        `/api/declaration/private/operateurs/${lotRevendications.idOperateur}/mouvement-revendicationsGroupeAvantFacture/${lotRevendications.campagne}`,
        this.mapper.serialize(lotRevendications.revendications)
      ).pipe(
        map(objetFactures => this.mapper.deserializeArray(objetFactures, ObjetFactureDTO)));
    }

  creerTransaction(idOperateur: number, annee: string, transaction: SaisieTransaction): any {
    return this.http.post(
      `/api/declaration/private/operateurs/${idOperateur}/mouvement-transactions/${annee}`,
      this.mapper.serialize(transaction))
      .pipe(map(() => { }));
  }

  getTransaction(idOperateur: number, idMouvement: number): Observable<SaisieTransaction> {     
    
    return forkJoin(
      this.http.get<SaisieTransaction>(`/api/declaration/private/operateurs/${idOperateur}/mouvement-transactions/${idMouvement}`),
      this.operateurSitesService.getSitesOperateur(idOperateur))
        .pipe(
          map(([transaction,sites]) => this.mapper.deserializeObject(transaction, SaisieTransaction,Object.assign({sites}))),
          this.produitsService.getProduitPipe()
        );
  }

  getTirage(idOperateur: number, idMouvement: number): Observable<SaisieTirage> {     
    
    return forkJoin(
      this.http.get<SaisieTirage>(`/api/declaration/private/operateurs/${idOperateur}/mouvement-tirage/${idMouvement}`),
      this.operateurSitesService.getSitesOperateur(idOperateur),
      this.referentielService.getReferentiel())
        .pipe(
          map(([tirage,sites, ref]) => this.mapper.deserializeObject(tirage, SaisieTirage,Object.assign({sites}, ref))) ,
          tap(tirage => { 
            this.produitsService.getProduitByCode(tirage.codeProduit).subscribe(produit => tirage.produit = produit);
          }),
          tap(tirage => { 
            this.produitsService.getProduitByCode(tirage.codeProduitBase).subscribe(produit => tirage.produitBase = produit);
          })
        );
  }

  getConditionnement(idOperateur: number, idMouvement: number): Observable<SaisieConditionnement> {     
    
    
      return this.http.get<SaisieConditionnement>(`/api/declaration/private/operateurs/${idOperateur}/mouvement-conditionnement/${idMouvement}`)
        .pipe(
          map(conditionnement => this.mapper.deserializeObject(conditionnement, SaisieConditionnement)),          
          tap(conditionnement => { 
            this.operateurSitesService.getSitesOperateur(idOperateur).subscribe(sites => {
              conditionnement.site = sites.find(s => s.id === conditionnement.idSite);
              });
          })
          ,tap(conditionnement => { 
            this.produitsService.getProduitByCode(conditionnement.codeProduit).subscribe(produit => conditionnement.produit = produit);
          })          
        );
  }

  getRevendication(idOperateur: number, idMouvement: number): Observable<SaisieRevendication> {      
    
    return forkJoin(
      this.http.get<SaisieRevendication>(`/api/declaration/private/operateurs/${idOperateur}/mouvement-revendication/${idMouvement}`),
      this.operateurSitesService.getSitesOperateur(idOperateur),
      this.referentielService.getReferentiel())
      .pipe(
        map(([revendication,site, ref]) => this.mapper.deserializeObject(revendication, SaisieRevendication,Object.assign({site},ref)))
      );
  }

  getChangementDenomination(idOperateur: number, idMouvement: number): Observable<ChangementDenomination> {     
    
     
    return forkJoin(
      this.http.get<ChangementDenomination>(`/api/declaration/private/operateurs/${idOperateur}/mouvement-changement-denomination/${idMouvement}`),
      this.referentielService.getReferentiel())
        .pipe(
          map(([changement, ref]) => this.mapper.deserializeObject(changement, ChangementDenomination, Object.assign(ref))),
          tap(changement => { 
            this.produitsService.getProduitByCode(changement.codeProduitOrigine).subscribe(produit => changement.produitOrigine = produit);
          })
          ,tap(changement => { 
            this.produitsService.getProduitByCode(changement.codeProduitDestination).subscribe(produit => changement.produitDestination = produit);
          })
          ,tap(changement => { 
            this.operateurSitesService.getSitesOperateur(idOperateur).subscribe(sites => {
              changement.site = sites.find(s => s.id === changement.idSite);
              });
          })
          // ,tap(changement => {
          //   this.referentielService.getReferentiel().subscribe(ref => {
          //     changement.typeChangement = ref.
          //   })
          //})

        )
  }

  creerTirage(idOperateur: number, annee: string, transaction: SaisieTirage): any {
    return this.http.post(
      `/api/declaration/private/operateurs/${idOperateur}/mouvement-tirages/${annee}`,
      this.mapper.serialize(transaction))
      .pipe(map(() => { }));
  }

  /**
   * Retourne la liste des mouvements d'un opérateur
   * @param idOperateur L'identifiant de l'opérateur concerné
   * @param annee L'année concernée
   * @returns Un observable contenant une colelction d'HistoriqueMouvement
   * @see HistoriqueMouvement
   */
  getHistoriqueMouvement(idOperateur: number, annee: string): Observable<HistoriqueMouvement[]> {
    return this.http.get<object[]>(
      `/api/declaration/private/operateurs/${idOperateur}/historiqueMouvement/${annee}`
      // '/assets/mocks/historique-mouvements.json'
    ).pipe(
      map(historiques => this.mapper.deserializeArray(historiques, HistoriqueMouvement)),
      this.produitsService.getProduitPipe()
    );
  }

  /**
   * Retourne la liste des mouvements non lus d'un organisme
   * @returns Une observable contenant une collection d'HistoriqueMouvement
   * @see HistoriqueMouvement
   */
  getSuiviMouvement(raisonSociale: string, annee: string, non_controlable: string, debutDate: moment.Moment, finDate: moment.Moment, suivi: string): Observable<HistoriqueMouvement[]> {
        
    const dateConverter = new DateConverter();
    let params = new HttpParams();

    params = (raisonSociale !== null && raisonSociale.length > 0) ? params.set('raisonSociale', raisonSociale) : params;
    params = (annee !== null ) ? params.set('annee', annee) : params;
    params = (suivi !== null ) ? params.set('suivi', suivi) : params;
    params = (non_controlable !== null ) ? params.set('non_controlable', non_controlable.toString()) : params;
    params = (debutDate) ? params.set('debutDate', dateConverter.serialize(debutDate)) : params;
    params = (finDate) ? params.set('finDate', dateConverter.serialize(finDate)) : params;
    
    return this.http.get<object[]>(
      `/api/declaration/private/historiqueMouvementFiltre`,
      {params}
      // '/assets/mocks/suivi-mouvements.json'
      
    ).pipe(
      map(historiques => this.mapper.deserializeArray(historiques, HistoriqueMouvement)),
      this.produitsService.getProduitPipe()
    );
  }

  countSuiviMouvement(): Observable<number> {
    return this.http.get<number>(`/api/declaration/private/historiqueMouvementNonLu/count`);
  }

  /**
   * Passe une collection d'HistoriqueMouvement à lu
   * @param ids Une collection d'identifiants d'HistoriqueMouvement
   * @see HistoriqueMouvement
   */
  setMouvementLu(ids: number[]): Observable<void> {
    return this.http.patch('/api/declaration/private/mouvement-statut-lu', ids).pipe(
      map(() => { })
    );
  }

  setMouvementNonLu(ids: number[]): Observable<void> {
    return this.http.patch('/api/declaration/private/mouvement-statut-non-lu', ids).pipe(
      map(() => { })
    );
  }

/**
   * Passe un mouvement à estNonControlable
   * @param id Un identifiant de Mouvement
   * @see Mouvement
   */
  setMouvementNonControlable(id: number,ischeck: boolean): any {
  return this.http.patch(`/api/declaration/private/mouvement-statut-non-controlable/${id}/${ischeck}`,null).pipe(
    map(() => { })
  );

  }


  getNumeroDossier(ids: number[]): Observable<string[]>{

    return this.http.post<string[]>('/api/declaration/private/mouvement/numeroDossier',ids);

  }



}
