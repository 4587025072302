import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Cepage } from 'src/app/data/declaration/models/cepage.model';
import { MouvementDestination } from 'src/app/data/declaration/models/mouvement-destination.model';
import { Referentiel } from 'src/app/data/declaration/models/referentiel.model';
import { SaisieRevendication } from 'src/app/data/declaration/models/saisie-revendication.model';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { OperateurSitesService } from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';

@Component({
  selector: 'app-vue-revendication',
  templateUrl: './vue-revendication.component.html',
  styleUrls: ['./vue-revendication.component.scss']
})
export class VueRevendicationComponent implements OnInit {

  @Input() idMouvement :number;
  @Input() public idOperateur: number;
  
  revendication : SaisieRevendication;
  public referentiel: Referentiel;
  
    constructor(
      public readonly modal: NgbActiveModal,
      private readonly operateurSitesService: OperateurSitesService,
      private readonly referentielService: ReferentielService,
      private readonly translate: TranslateService,
      private readonly mouvementsProduitsService: MouvementsProduitsService,
      private readonly modalService: NgbModal,
    ) { }
  
    ngOnInit() {
      this.loadData();
    }
  
    /** Charge la liste des mouvements non lus et le référentiel */
    private loadData() {
      forkJoin(
        this.mouvementsProduitsService.getRevendication(this.idOperateur,  this.idMouvement ),
        this.referentielService.getReferentiel()
      ).pipe().subscribe(
        ([revendication, referentiel]) => {
          this.revendication = revendication;
          this.referentiel = referentiel;
        });
    }
  
     /** Ferme la fenête **/
    public onClose(): void {
      this.modal.dismiss();
    }

    public getCepages(cepages: Cepage[]): string {
      if(cepages.length > 0){
        return cepages.map(cepage => cepage.libelle).join();
      }
      else{
        return '';
      }      
    }

    public getDestinations(destinations: MouvementDestination[]): string {
      if(destinations.length > 0){
        return destinations.map(destination => destination.libelle).join();
      }
      else{
        return '';
    }      
  }

}
