<div>
  <div class="modal-header">
    <h4 class="modal-title">Conditionnement</h4>
    <div class="pointer">
      <fa-icon icon="times" (click)="onClose()"></fa-icon>
    </div>  
  </div>

  <div class="modal-body">
    
      <div class="row" *ngIf="conditionnement">
        <div class="col">
          <div  class="py-2">
            <div class="libelle" >{{ 'page.declarations.synthese.modal.saisirConditionnement.produit' | translate }}</div>
            <div class="valeur" >{{conditionnement.produit!.libelle}}</div>
          </div>

          <div  class="py-2">
            <div class="libelle" >{{ 'page.declarations.synthese.modal.saisirConditionnement.dateConditionnement' | translate }}</div>
            <div class="valeur" >{{conditionnement.date | date:'dd/MM/yyyy'}}</div> 
          </div>

          <div  class="py-2">
            <div class="libelle" >{{ 'page.declarations.synthese.modal.saisirConditionnement.logement' | translate }}</div>
            <div class="valeur" >{{conditionnement.logement}}</div>
          </div>

          <div  class="py-2">
            <div class="libelle" >{{ 'page.declarations.synthese.modal.saisirConditionnement.numeroLot' | translate }}</div>
            <div class="valeur" >{{conditionnement.numeroLot}}</div>
        </div>
        </div>

        <div class="col" >
          <!-- Formulaire assemblages -->
          <div class="libelle" >{{ 'page.declarations.synthese.modal.saisirConditionnement.volume' | translate }}</div>
            <div *ngFor="let assemblage of conditionnement.assemblages" >
              <div class="row" >
                <div class="col-md-4">{{ 'page.declarations.synthese.modal.saisirConditionnement.annee' | translate }}</div>
                <div class="col-md-8">{{ 'page.declarations.synthese.modal.saisirConditionnement.volume' | translate }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</div>
              </div>
              <div class="row">
                <div class="col-md-4">{{assemblage.annee}}</div>
                <div class="col-md-8">{{assemblage.volume}}</div>  
              </div>
            </div>
          <!-- Fin formulaire assemblages -->
          <div >
            <div class="libelle" >{{ 'page.declarations.synthese.modal.saisirConditionnement.volumeTotal' | translate }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</div>
            <div class="valeur" >{{conditionnement.volume}}</div>
          </div>

          <hr />
          <div  class="py-2">
            <div class="libelle" >{{ 'page.declarations.synthese.modal.saisirConditionnement.entreposage' | translate }}</div>
            <div class="valeur" >{{ conditionnement.site.adresse }}</div>        
          </div>
          

          <!-- Formulaire Contenants -->
          <div *ngFor="let contenant of conditionnement.contenants">
              <div class="libelle" >{{ 'page.declarations.synthese.modal.saisirConditionnement.contenant' | translate }}</div>
              <div class="row" >
                <div class="col-md-5">{{ 'page.declarations.synthese.modal.saisirConditionnement.type' | translate }}</div>
                <div class="col-md-3">{{ 'page.declarations.synthese.modal.saisirConditionnement.nombre' | translate }}</div>
                <div class="col-md-3">{{ 'page.declarations.synthese.modal.saisirConditionnement.volume' | translate }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</div>
              </div>
              <div class="row">
                <div class="col-md-4" >{{getLibelleContenant(contenant.idType)}}</div>
                <div class="col-md-4" >{{contenant.nombre}}</div>
                <div class="col-md-3" >{{contenant.volume}}</div>                
            </div>
            </div>
          
        </div>
      </div>

    
  </div>
</div>
