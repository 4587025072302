import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Referentiel } from 'src/app/data/declaration/models/referentiel.model';
import { SaisieTransaction } from 'src/app/data/declaration/models/saisie-transaction.model';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { OperateurSitesService } from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';

@Component({
  selector: 'app-vue-transaction',
  templateUrl: './vue-transaction.component.html',
  styleUrls: ['./vue-transaction.component.scss']
})
export class VueTransactionComponent implements OnInit {

@Input() idMouvement :number;
@Input() public idOperateur: number;

transaction : SaisieTransaction;
public referentiel: Referentiel;

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly operateurSitesService: OperateurSitesService,
    private readonly referentielService: ReferentielService,
    private readonly translate: TranslateService,
    private readonly mouvementsProduitsService: MouvementsProduitsService,
    private readonly modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  /** Charge la liste des mouvements non lus et le référentiel */
  private loadData() {
    forkJoin(
      this.mouvementsProduitsService.getTransaction(this.idOperateur,  this.idMouvement ),
      this.referentielService.getReferentiel()
    ).pipe().subscribe(
      ([transaction, referentiel]) => {
        this.transaction = transaction;
        this.referentiel = referentiel;
      });
  }

   /** Ferme la fenête */
   public onClose(): void {
    this.modal.dismiss();
  }

}
