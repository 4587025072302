<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>page.declarations.synthese.changementProduit</h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="frmChangementDenomination" #form="ngForm" (ngSubmit)="submit()">
      <div class="cols">
        <div class="col">          
          <div class="form-group">
            <label for="origine" translate>page.declarations.synthese.modal.changementDenomination.origine</label>
            <ng-select bindLabel="libelle" formControlName="origine" [items]="produitsOrigine" [multiple]="false" [closeOnSelect]="true"  [searchable]="true" [ngClass]="{'is-invalid': form.submitted && devient.invalid}">
            </ng-select>
          </div>

          <div class="form-group">
            <label for="devient" translate>page.declarations.synthese.modal.changementDenomination.devient</label>
            <select [formControl]="devient" id="devient" name="devient" class="form-control" [ngClass]="{'is-invalid': form.submitted && devient.invalid, 'select-placeholder' : (devient.value === null && !devient.dirty)}">
              <option [ngValue]="null" hidden selected>{{ 'page.declarations.synthese.modal.changementDenomination.trProduits' | translate }}</option>
              <option *ngFor="let produitDestination of produits" [ngValue]="produitDestination">{{ produitDestination?.produit?.libelle }} ({{ produitDestination?.typeChangement }})</option>
            </select>
          </div>

          <div class="form-group" *ngIf="typeCahier === 'IGP'">
            <label for="select-cepages" translate>page.declarations.synthese.modal.changementDenomination.cepages</label>
            <app-multiselect id="select-cepages" maxWidth="initial" bindLabel="libelle" controlName="cepages" [items]="listeCepage" [ngClass]="{'is-invalid': form.submitted && cepages.invalid}"></app-multiselect>
          </div>

          <div class="form-group">
            <label for="volume" translate>page.declarations.synthese.modal.changementDenomination.volume</label>
            <input type="text" [formControl]="volume" id="volume" name="volume" class="form-control" [ngClass]="{'is-invalid': form.submitted && volume.invalid, 'select-placeholder' : (volume.value === null && !volume.dirty)}" />
          </div>

          <div class="form-group">
            <label for="logement" translate>page.declarations.synthese.modal.changementDenomination.logement</label>
            <input type="text" [formControl]="logement" id="logement" name="logement" class="form-control" [ngClass]="{'is-invalid': form.submitted && logement.invalid, 'select-placeholder' : (logement.value === null && !logement.dirty)}" />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <p><label for="typeDeLot" translate>page.declarations.synthese.modal.changementDenomination.typeDeLot</label></p>
            <input type="radio" [formControl]="typeDeLot" id="vrac" name="typeDeLot" value="VRAC" checked /> Vrac
            <input type="radio" [formControl]="typeDeLot" id="conditionne" name="typeDeLot" value="CONDITIONNE" /> Conditionné
          </div>

          <div class="form-group">
            <label for="numeroLot" translate>page.declarations.synthese.modal.changementDenomination.numeroLot</label>
            <input type="text" [formControl]="numeroLot" id="numeroLot" name="numeroLot" class="form-control" [ngClass]="{'is-invalid': form.submitted && numeroLot.invalid, 'select-placeholder' : (numeroLot.value === null && !numeroLot.dirty)}" />
          </div>

          <div class="form-group">
            <label for="entreposage" for="entreposage" translate>page.declarations.synthese.modal.changementDenomination.entreposage</label>
            <select [formControl]="entreposage" id="entreposage" name="entreprosage" class="form-control" [ngClass]="{'is-invalid': form.submitted && entreposage.invalid, 'select-placeholder' : (entreposage.value === null && !entreposage.dirty)}">
              <option [ngValue]="null" hidden selected>{{ 'page.declarations.synthese.modal.changementDenomination.listeSites' | translate }}</option>
              <option *ngFor="let siteOperateur of sitesOperateur" [ngValue]="siteOperateur">{{ siteOperateur?.adresse }}</option>
            </select>
          </div>

          <div class="form-group" *ngIf="typeCahier === 'IGP'">
            <label for="observation" translate>page.declarations.synthese.modal.changementDenomination.observation</label>
            <textarea formControlName="observation" class="form-control" [ngClass]="{'is-invalid': form.submitted && observation.invalid}"></textarea>
          </div>
        </div>
      </div>

      <div class="actions-bar">
        <button type="button" (click)="close()" class="btn btn-light" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate>label.valider</button>
      </div>
    </form>
  </div>
</div>
