import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Cepage } from 'src/app/data/declaration/models/cepage.model';
import { ChangementDenomination } from 'src/app/data/declaration/models/changement-denomination.model';
import { Referentiel } from 'src/app/data/declaration/models/referentiel.model';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { OperateurSitesService } from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';

@Component({
  selector: 'app-vue-changement-denomination',
  templateUrl: './vue-changement-denomination.component.html',
  styleUrls: ['./vue-changement-denomination.component.scss']
})
export class VueChangementDenominationComponent implements OnInit {

  @Input() idMouvement :number;
  @Input() public idOperateur: number;
  
  changement : ChangementDenomination;
  public referentiel: Referentiel;
  
    constructor(
      public readonly modal: NgbActiveModal,
      private readonly operateurSitesService: OperateurSitesService,
      private readonly referentielService: ReferentielService,
      private readonly translate: TranslateService,
      private readonly mouvementsProduitsService: MouvementsProduitsService,
      private readonly modalService: NgbModal,
    ) { }
  
    ngOnInit() {
      this.loadData();
    }
  
    /** Charge la liste des mouvements non lus et le référentiel */
    private loadData() {
      forkJoin(
        this.mouvementsProduitsService.getChangementDenomination(this.idOperateur,  this.idMouvement ),
        this.referentielService.getReferentiel()
      ).pipe().subscribe(
        ([changement, referentiel]) => {
          this.changement = changement;
          this.referentiel = referentiel;
        });
    }
  
     /** Ferme la fenête */
     public onClose(): void {
      this.modal.dismiss();
    }

    public getCepages(cepages: Cepage[]): string {
     
      if(cepages.length > 0){
        return cepages.map(cepage => cepage.libelle).join();
      }
      else{
        return '';
      }      
    }

    public getConditionnement(conditionnement: string): string {
     
      if(conditionnement.length > 0){
        return this.referentiel.conditionnements.find(c => c.code === conditionnement).libelle;
      }
      else{
        return '';
      }      
    }
  

}
