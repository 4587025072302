import { Component, OnInit } from '@angular/core';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { HistoriqueMouvement } from 'src/app/data/declaration/models/historique-mouvement.model';
import { SessionContext, NavigationContext } from 'src/app/core/services/config/app.settings';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { Referentiel } from 'src/app/data/declaration/models/referentiel.model';
import { RefMouvement } from 'src/app/data/declaration/models/ref-mouvement.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VueTransactionComponent } from '../../../suivi-declarations/modal/vue-transaction/vue-transaction.component';
import { VueChangementDenominationComponent } from '../../../suivi-declarations/modal/vue-changement-denomination/vue-changement-denomination.component';
import { VueConditionnementComponent } from '../../../suivi-declarations/modal/vue-conditionnement/vue-conditionnement.component';
import { VueTirageComponent } from '../../../suivi-declarations/modal/vue-tirage/vue-tirage.component';
import { VueRevendicationComponent } from '../../../suivi-declarations/modal/vue-revendication/vue-revendication.component';

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.scss']
})
export class HistoriqueComponent implements OnInit {
  static routes: Route[] = [
    { path: '', component: HistoriqueComponent, data: { menu: { libelle: 'Historique déclarations' } } },
    { path: ':annee', component: HistoriqueComponent }
  ];
  public historiques: HistoriqueMouvement[];
  public displayedHistoriques: HistoriqueMouvement[] = [];
  public referentiel: Referentiel;
  get idOperateur() {
    return (SessionContext.get('utilisateurType') === UtilisateurTypeCode.OPERATEUR) ?
      SessionContext.get('idIntervenant') : NavigationContext.get('idOperateur');
  }
  // Formulaires de recherche
  public formAnnee: FormGroup;
  get annee() { return this.formAnnee.get('annee'); }
  public formRecherche: FormGroup;
  get numeroLot() { return this.formRecherche.get('numeroLot'); }
  get debut() { return this.formRecherche.get('debut'); }
  get fin() { return this.formRecherche.get('fin'); }
  get cdc() { return this.formRecherche.get('cdc'); }
  get produit() { return this.formRecherche.get('produit'); }
  get numeroDossier() { return this.formRecherche.get('numeroDossier'); }
  get typeDeclatation() { return this.formRecherche.get('typeDeclatation'); }

  constructor(
    private readonly mouvementsProduitsService: MouvementsProduitsService,
    private readonly referentielService: ReferentielService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly modalService: NgbModal
  ) { }

  ngOnInit() {
    // Initialiser le formulaire de recherche
    this.formAnnee = this.fb.group({
      annee: [undefined, [Validators.required, Validators.pattern(/(19|20)\d\d/)]],
    });

    // Initialiser le formulaire de recherche avancée
    this.formRecherche = this.fb.group({
      numeroLot: undefined,
      debut: undefined,
      fin: undefined,
      cdc: undefined,
      produit: undefined,
      typeDeclatation: undefined,
      numeroDossier: undefined
    });

    // Ecouter le changement d'année depuis l'URL pour charger les données
    this.route.params.subscribe(vals => {
      const annee = vals.annee;
      if (annee) {
        this.annee.setValue(annee);
        this.loadData(annee);
      }
    });

    // Ecouter le changement d'année dans le formulaire pour charger les données
    this.annee.valueChanges.subscribe(annee => {
      if (this.annee.valid) {
        this.router.navigate([ annee ], { relativeTo: this.route.parent });
      }
    });

    // Ecouter les changement dans la recherche avancée
    this.numeroLot.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => this.filtrer());
    this.debut.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => this.filtrer());
    this.fin.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => this.filtrer());
    this.cdc.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => this.filtrer());
    this.produit.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => this.filtrer());
    this.typeDeclatation.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => this.filtrer());
    this.numeroDossier.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => this.filtrer());
    // Charger le référentiel pour obtenir les types de mouvements
    this.referentielService.getReferentiel().subscribe(
      referentiel => this.referentiel = referentiel
    );
  }

  /** Charge les historiques relatifs aux mouvements produits de l'opérateur */
  private loadData(annee: string): void {
    this.historiques = [];
    this.displayedHistoriques = [];
    this.numeroLot.setValue(undefined);
    this.debut.setValue(undefined);
    this.fin.setValue(undefined);
    this.cdc.setValue(undefined);
    this.produit.setValue(undefined);
    this.numeroDossier.setValue(undefined);
    this.mouvementsProduitsService.getHistoriqueMouvement(this.idOperateur, annee)
      .pipe(this.loaderService.operator())
      .subscribe(historiques => {
        this.historiques = historiques;
        this.displayedHistoriques = historiques;
      });
  }

  /**
   * Filtre la liste des historiques avec les données entrées
   * dans le formulaire de recherche avancée
   */
  public filtrer(): void {
    if (this.numeroLot.value || this.debut.value || this.fin.value || this.cdc.value || this.produit.value ||
      this.typeDeclatation.value || this.numeroDossier.value) {
      this.displayedHistoriques = this.historiques.filter(
        historique => {
          let result = true;
          if(this.numeroLot.value && !(historique.numeroLot.toLowerCase().includes(this.numeroLot.value.toLowerCase()))) {
            result = false;
          }
          // if (this.numeroLot.value && (historique.numeroLot !== this.numeroLot.value)) {
          //   result = false;
          // }
          if (this.numeroDossier.value && (historique.numeroDossier.toString() !== this.numeroDossier.value.toString())) {
            result = false;
          }
          if (this.debut.value)  {
            const debut = moment([this.debut.value.year, this.debut.value.month - 1, this.debut.value.day]);
            result = (historique.date >= debut) ? result : false;
          }
          if (this.fin.value) {
            const fin = moment([this.fin.value.year, this.fin.value.month - 1, this.fin.value.day]);
            result = (historique.date <= fin) ? result : false;
          }
          if(this.cdc.value && !(historique.libelleCahier.toLowerCase().includes(this.cdc.value.toLowerCase()))) {
            result = false;
          }
          if(this.produit.value && !(historique.produit.libelle.toLowerCase().includes(this.produit.value.toLowerCase()))) {
            result = false;
          }
          if(this.typeDeclatation.value && this.typeDeclatation.value.length > 0) {
            let codes = this.typeDeclatation.value.map(a => a.code);
            if(!codes.includes(historique.code)) {
              result = false;
            }
          }

          return result;
        }
      );
    } else {
      this.displayedHistoriques = this.historiques;
    }
  }

  public getTypeMouvement(code: string): string {
    let libelle = '';

    if (this.referentiel && this.referentiel.refMouvements) {
      const mouvement: RefMouvement = this.referentiel.refMouvements
        .find(mouvement => mouvement.code === code);
      if (mouvement) {
        libelle = mouvement.libelle;
      }
    }

    return libelle;
  }

  public onEdit(mouvement: HistoriqueMouvement): void {

    var obj;

    switch(mouvement.code){
      case 'TRANSACTION':
        obj = VueTransactionComponent;
        break;
      case 'CHANGEMENT_DENOMINATION':
        obj = VueChangementDenominationComponent;
        break;
      case 'CONDITIONNEMENT':
        obj = VueConditionnementComponent;
        break;
      case 'TIRAGE':
        obj = VueTirageComponent;
        break;
      case 'REVENDICATION':
        obj = VueRevendicationComponent;
        break;
    }    

        const modal_cd = this.modalService.open(obj, { backdrop: 'static', windowClass: 'app-modal-60' });
        modal_cd.componentInstance.idMouvement = mouvement.idMouvement;
        modal_cd.componentInstance.idOperateur = this.idOperateur;
    
  }

}
