import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Referentiel } from 'src/app/data/declaration/models/referentiel.model';
import { SaisieTirage } from 'src/app/data/declaration/models/saisie-tirage.model';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { OperateurSitesService } from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';

@Component({
  selector: 'app-vue-tirage',
  templateUrl: './vue-tirage.component.html',
  styleUrls: ['./vue-tirage.component.scss']
})
export class VueTirageComponent implements OnInit {

  @Input() idMouvement :number;
@Input() public idOperateur: number;

tirage : SaisieTirage;
public referentiel: Referentiel;
prestataire: string;

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly operateurSitesService: OperateurSitesService,
    private readonly operateurService: OperateursService,
    private readonly referentielService: ReferentielService,
    private readonly translate: TranslateService,
    private readonly mouvementsProduitsService: MouvementsProduitsService,
    private readonly modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  /** Charge la liste des mouvements non lus et le référentiel */
  private loadData() {
    forkJoin(
      this.mouvementsProduitsService.getTirage(this.idOperateur,  this.idMouvement ),
      this.referentielService.getReferentiel()
    ).pipe().subscribe(
      ([tirage, referentiel]) => {
        this.tirage = tirage;
        this.referentiel = referentiel;
        if(tirage.idPrestataire !== undefined){
          this.operateurService.getOperateur(tirage.idPrestataire).pipe().subscribe(operateur =>{
            this.prestataire = operateur.raisonSociale + ' ( '+operateur.adresse + ' ' + operateur.codePostal + ' ' + operateur.ville+' )';
          });      
        }   
      else{
        return "";
      }
      });
  }

  private getLibelleContenant(idType: number){

    return this.referentiel.refContenantList.find(c => c.idContenant === idType).libelleContenant;

  }  

   /** Ferme la fenête */
   public onClose(): void {
    this.modal.dismiss();
  }
}
