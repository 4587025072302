<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
    page.declarations.synthese.modal.saisir-revendication.title
    </h4>
    <div class="pointer">
      <fa-icon icon="times" (click)="onClose()"></fa-icon>
    </div>  
  </div>

  <div class="modal-body">
    <!-- Formulaire de saisie d'une revendication -->
    
      <div class="row" *ngIf="revendication">
        <!-- Colonne de gauche -->
        <div class="col">         

          <div>
            <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.produit</div>
            <div class="valeur">{{revendication.libelleProduit}}</div>
          </div>

          <div  class="py-2">
            <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.date</div>
            <div class="valeur" >{{revendication.date | date:'dd/MM/yyyy'}}</div> 
          </div>

          <div  class="py-2">
            <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.entreposage</div>
            <div class="valeur" *ngIf="revendication.entreposage" >{{ revendication.entreposage.adresse }}</div>
          </div>

          <div  class="py-2">
            <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.cepages</div>
            <div class="valeur" >{{getCepages(revendication.cepages)}}</div>
          </div>

          <div  class="py-2">
            <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.logement</div>
            <div class="valeur" >{{revendication.logement}}</div>
          </div>
        </div>
        <!-- Fin colonne de gauche -->

        <!-- Colonne de droite -->
        <div class="col">
          <div>
            <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.numeroLot</div>
            <div class="valeur" >{{revendication.numeroLot}}</div>
          </div>

          <div  class="py-2" *ngIf="revendication.degre">
            <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.degre</div>
            <div class="valeur" >{{revendication.degre}}</div>
          </div>

          <div  class="py-2">
            <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.volume</div>
            <div class="valeur" >{{revendication.volume}}</div>
          </div>

          <div  class="py-2">
            <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.destinations</div>
            <div class="valeur" >{{getDestinations(revendication.destinations)}}</div>
          </div>

          <div  class="py-2">
            <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.observation</div>
            <div class="valeur" >{{ revendication.observation }}</div>
        </div>
        </div>
        <!-- Fin colonne de droite -->
      </div>

     
  </div>
</div>
