import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OperateurSitesService } from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import { Site } from 'src/app/data/declaration/models/site.model';
import { SessionContext, NavigationContext } from 'src/app/core/services/config/app.settings';
import { ProduitDestination } from 'src/app/data/declaration/models/produit-destination.model';
import { ChangementDenomination } from 'src/app/data/declaration/models/changement-denomination.model';
import { Produit } from 'src/app/data/habilitation/models/produit.model';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { TypeCahierCode } from 'src/app/data/habilitation/models/enums/type-cahier-code.enum';
import { StockCode } from 'src/app/data/declaration/models/enums/stock.enum';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { forkJoin } from 'rxjs';
import { Cepage } from 'src/app/data/declaration/models/cepage.model';
import { TypeChangementProduitCode } from 'src/app/data/declaration/models/enums/type-changement-produit-code.enum';
import { FunctionalError } from 'src/app/shared/errors/functional.error';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

enum FormSates {
  SELECT_ORIGINE = 'select-origine',
  SELECT_OTHERS = 'select-others',
  SELECT_OTHERS_LOCK_ORIGINE = 'select-others-lock-origine'
}

@Component({
  selector: 'app-changements-denomination',
  templateUrl: './changements-denomination.component.html',
  styleUrls: ['./changements-denomination.component.scss']
})
export class ChangementsDenominationComponent implements OnInit {
  @Input()
  public campagne: string;
  @Input()
  public produitsOrigine: Produit[];
  @Input()
  typeCahier: TypeCahierCode;
  @Input()
  stockOrigine: StockCode;
  @Input()
  origineHorsBacchus = false;


  public produits: ProduitDestination[];
  public listeCepage: Cepage[];
  public sitesOperateur: Site[] = [];

  private originesMultiples = true;

  public frmChangementDenomination: FormGroup;
  idOperateur: number;
  get observation() { return this.frmChangementDenomination.get('observation'); }
  get cepages() { return this.frmChangementDenomination.get('cepages'); }
  get origine() { return this.frmChangementDenomination.get('origine'); }
  get devient() { return this.frmChangementDenomination.get('devient'); }
  get volume() { return this.frmChangementDenomination.get('volume'); }
  get logement() { return this.frmChangementDenomination.get('logement'); }
  get typeDeLot() { return this.frmChangementDenomination.get('typeDeLot'); }
  get numeroLot() { return this.frmChangementDenomination.get('numeroLot'); }
  get entreposage() { return this.frmChangementDenomination.get('entreposage'); }

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly mouvementsProduitService: MouvementsProduitsService,
    private readonly operateurSiteService: OperateurSitesService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly referentielService: ReferentielService,
    private readonly loader: AdvBootstrapLoaderService
  ) { }

  positifStrictValidator(control: FormControl) {
    const value = control.value;

    if (value <= 0) {
      return { invalid: true };
    }

    return null;
  }

  ngOnInit() {

    this.idOperateur = SessionContext.get('utilisateurType') === UtilisateurTypeCode.OPERATEUR ?
      SessionContext.get('idIntervenant') :
      NavigationContext.get('idOperateur');

    let produitOrigine: Produit;
    if (this.produitsOrigine.length === 1) {
      produitOrigine = this.produitsOrigine[0];
      this.originesMultiples = false;
    }

    this.frmChangementDenomination = this.fb.group({
      origine: [produitOrigine],
      devient: [undefined, Validators.required],
      volume: [undefined, [Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/), this.positifStrictValidator]],
      logement: [undefined, Validators.required],
      typeDeLot: ['VRAC', Validators.required],
      numeroLot: [undefined, this.typeCahier === TypeCahierCode.IGP ? Validators.required : undefined],
      entreposage: [undefined, Validators.required],
      cepages: [undefined],
      observation: [undefined]
    });

    if (this.origine.value) {
      this.changeFormState(FormSates.SELECT_OTHERS_LOCK_ORIGINE);
      this.loadData();
    } else {
      this.changeFormState(FormSates.SELECT_ORIGINE);
      this.origine.valueChanges.subscribe(val => this.onOrigineChange(val));
    }
  }

  onOrigineChange(val: Produit) {
    if (val) {
      this.changeFormState(FormSates.SELECT_OTHERS);
      this.loadData();
    } else {
      this.changeFormState(FormSates.SELECT_ORIGINE);
      this.produits = [];
    }
  }

  private changeFormState(step: FormSates) {
    const origineDisabled = step === FormSates.SELECT_OTHERS_LOCK_ORIGINE;
    const othersDisabled = step === FormSates.SELECT_ORIGINE;

    for (const name in this.frmChangementDenomination.controls) {
      if (name === 'origine') {
        this.frmChangementDenomination.controls[name][origineDisabled ? 'disable' : 'enable']({ emitEvent: false });
      } else {
        this.frmChangementDenomination.controls[name][othersDisabled ? 'disable' : 'enable']({ emitEvent: false });
      }
    }
  }

  loadData() {
    forkJoin(
      this.mouvementsProduitService.getProduitsDestinationDenomination(this.idOperateur,this.origine.value.code, this.typeCahier),
      this.referentielService.getReferentiel()
    ).pipe(
      this.loader.operator()
    ).subscribe(
      ([produits, ref]) => {
        this.produits = produits.filter(produit => {
          if (this.typeCahier === TypeCahierCode.IGP && this.origineHorsBacchus === false && this.stockOrigine === StockCode.ISSU_DR) {
            return produit.codeTypeChangement === TypeChangementProduitCode.DECLASSEMENT;
          }
          else{
            return produit.codeTypeChangement === TypeChangementProduitCode.DECLASSEMENT || produit.codeTypeChangement === TypeChangementProduitCode.RENONCEMENT || produit.codeTypeChangement === TypeChangementProduitCode.REPLI
            || produit.codeTypeChangement === TypeChangementProduitCode.CHANGEMENT_DENO ;
          }
          
        }).sort((a,b) => ( a.produit.libelle < b.produit.libelle ? -1 : 1));
        if (this.produits.length === 0) {
          throw new FunctionalError('BACCHUS_BUSINESS_CHANGEMENT_DENO_IMPOSSIBLE');
        }

        this.listeCepage = ref.cepages;
      },
      error => {
        if (error instanceof HttpErrorResponse && error.error.code === 'BACCHUS_BUSINESS_CHANGEMENT_DENO_IMPOSSIBLE' && this.originesMultiples === false) {
          this.modal.dismiss();
        }
        throw error;
      }
    );


    this.operateurSiteService.getSitesOperateur(this.idOperateur).subscribe(
      (sites: Site[]) => this.sitesOperateur = sites
    );
  }

  submit() {
    if (this.frmChangementDenomination.valid) {
      const changementDenomination = Object.assign(
        new ChangementDenomination(),
        {
          codeStock: this.stockOrigine,
          codeProduitOrigine: this.origine.value.code,
          codeProduitDestination: this.devient.value.codeProduit,
          quantite: +this.volume.value.replace(/,/, '.'),
          logement: this.logement.value,
          numeroLot: this.numeroLot.value,
          typeConditionnement: this.typeDeLot.value,
          idSite: this.entreposage.value.id,
          cepages: this.cepages.value,
          observation: this.observation.value
        }
      );

      this.mouvementsProduitService.setChangementDenomination(
        this.idOperateur,
        changementDenomination,
        this.campagne
      ).pipe(
        this.loader.operator()
      ).subscribe(() => {
        this.translate.get('page.declarations.synthese.modal.changementDenomination.update-ok').subscribe(msg => {
          this.toastr.success('', msg);
        });
        this.modal.close();
      });
    }
  }

  close() {
    this.modal.dismiss();
  }
}
